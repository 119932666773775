import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec } from '../util/CustomHttpParameterCodec';
import { GooglePlacesApiKey } from './GooglePlacesApiKey';
import { GooglePlacesAutoCompleteRequest } from './types/GooglePlacesAutoCompleteRequest';
import { GooglePlacesAutoCompleteResponse } from './types/GooglePlacesAutoCompleteResponse';
import { GooglePlacesPlaceDetailsResponse } from './types/GooglePlacesPlaceDetailsResponse';

@Injectable({
  providedIn: 'root',
})
export class GooglePlacesApi {
  readonly #httpClient = inject(HttpClient);
  readonly #apiKey = inject(GooglePlacesApiKey);
  readonly #paramEncoder: HttpParameterCodec = new CustomHttpParameterCodec();

  protected basePath = 'https://places.googleapis.com/v1/places';
  public defaultHeaders = new HttpHeaders();

  constructor() {
    if (!this.#apiKey) {
      throw new Error('Google Places API Key is required');
    }
  }

  autocomplete(request: GooglePlacesAutoCompleteRequest): Observable<GooglePlacesAutoCompleteResponse>;
  autocomplete(request: GooglePlacesAutoCompleteRequest, as: 'response'): Observable<HttpResponse<GooglePlacesAutoCompleteResponse>>;
  autocomplete(request: GooglePlacesAutoCompleteRequest, as: 'event'): Observable<HttpEvent<GooglePlacesAutoCompleteResponse>>;
  autocomplete(request: GooglePlacesAutoCompleteRequest, as: any = 'body'): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('X-Goog-Api-Key', this.#apiKey ?? '');

    const uri = `${this.basePath}:autocomplete`;

    return this.#httpClient.post<GooglePlacesAutoCompleteResponse>(uri, request, {
      observe: as,
      responseType: 'json',
      headers,
      reportProgress: false,
      transferCache: true,
    });
  }

  details(placeId: string): Observable<GooglePlacesPlaceDetailsResponse>;
  details(placeId: string, as: 'response'): Observable<HttpResponse<GooglePlacesPlaceDetailsResponse>>;
  details(placeId: string, as: 'event'): Observable<HttpEvent<GooglePlacesPlaceDetailsResponse>>;
  details(placeId: string, as: any = 'body'): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('X-Goog-Api-Key', this.#apiKey ?? '');
    headers = headers.set(
      'X-Goog-FieldMask',
      'id,displayName,formattedAddress,addressComponents,location,adrFormatAddress,shortFormattedAddress,types,viewport,primaryType,primaryTypeDisplayName',
    );

    const uri = `${this.basePath}/${encodeURIComponent(String(placeId))}`;
    return this.#httpClient.get<any>(uri, {
      observe: as,
      responseType: 'json',
      headers,
      reportProgress: false,
      transferCache: true,
    });
  }
}
