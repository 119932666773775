import { Injectable, inject } from '@angular/core';
import { GooglePlacesApi } from './places/google-place.api';

@Injectable({
  providedIn: 'root',
})
export class GooglePlacesService {
  #placesApi = inject(GooglePlacesApi);

  autocomplete(input: string, includedRegionCodes: string[] = []) {
    return this.#placesApi.autocomplete({
      input,
      includedRegionCodes,
      // doesn't work ... because google.....
      //includedPrimaryTypes: ['premise', 'street_address', 'subpremise', 'establishment', 'point_of_interest'],
    });
  }

  details(placeId: string) {
    return this.#placesApi.details(placeId);
  }
}
