import { Validators } from '@angular/forms';
import { TextFormControlOptions } from '../text';
import { extendTextFormControl } from '../text/extendTextFormControl';
import { DefaultEmailFormControlOptions, EmailFormControlOptions } from './EmailFormControlOptions';

export class EmailFormControl extends extendTextFormControl<EmailFormControlOptions & TextFormControlOptions>() {
  constructor(value: string, opts?: EmailFormControlOptions & { nonNullable: true });
  constructor(value: string | null, opts?: EmailFormControlOptions & { nonNullable?: false });
  constructor(value: string | null, opts?: EmailFormControlOptions) {
    super(value, {
      ...DefaultEmailFormControlOptions,
      ...opts,
      // maxLength: (opts?.maxLength ?? 254) >= 254 ? 254 : opts!.maxLength,
    } as EmailFormControlOptions & TextFormControlOptions);
    this.addValidators((c) => {
      const error = Validators.pattern(
        '(?:[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
      )(c);
      return error ? { email: true } : null;
    });
  }
}
