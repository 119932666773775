import { Type } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormControlOptionsEx } from '../options/FormControlOptionsEx';

export function FormControlEx<TData = any, TOptions extends FormControlOptionsEx = FormControlOptionsEx>(): Type<
  FormControl<TData> & {
    options?: Required<TOptions>;
  }
> {
  return class<T> extends FormControl {
    public readonly options: Required<TOptions>;
    constructor(value: T, options: Required<TOptions>) {
      super(value, {
        nonNullable: options?.nonNullable,
        updateOn: options?.updateOn,
        validators: options?.additionalValidators,
        asyncValidators: options?.additionalAsyncValidators,
      });
      this.options = options;
    }
  };
}
