import { Signal, signal, WritableSignal } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { extendTextFormControl } from '../text/extendTextFormControl';
import { PasswordAnalysis } from './PasswordAnalysis';
import { PasswordAnalyzer } from './PasswordAnalyzer';
import { DefaultPasswordFormControlOptions, PasswordFormControlOptions } from './PasswordFormControlOptions';

const calcHasFeedbackValidators = (opts: PasswordFormControlOptions) =>
  opts.warnStrength !== null ||
  opts.errorStrength !== null ||
  opts.requireAlpha ||
  opts.requireNumber ||
  opts.requireUppercase ||
  opts.requireLowercase ||
  opts.requireSpecial ||
  !!opts.minLength;

export class PasswordFormControl extends extendTextFormControl<PasswordFormControlOptions>() {
  private readonly _analyzer;
  private readonly _analysis: WritableSignal<PasswordAnalysis>;

  public readonly hasFeedbackValidators: boolean;
  public readonly analysis: Signal<PasswordAnalysis>;

  constructor(value: string, opts?: PasswordFormControlOptions & { nonNullable: true });
  constructor(value: string | null, opts?: PasswordFormControlOptions & { nonNullable?: false });
  constructor(value: string | null, opts?: PasswordFormControlOptions) {
    super(value, {
      ...DefaultPasswordFormControlOptions,
      ...opts,
    } as any);
    this.hasFeedbackValidators = calcHasFeedbackValidators(this.options);

    this._analyzer = new PasswordAnalyzer(this.options);
    this._analysis = signal<PasswordAnalysis>(this._analyzer.analyzePassword(value ?? ''));
    this.analysis = this._analysis.asReadonly();

    this.addValidators((control) => {
      const analysis = this._analyzer.analyzePassword(control.value ?? '');
      if (!control.value) {
        setTimeout(() => this._analysis.set(analysis));
        return null;
      }
      let errors: ValidationErrors | null = null;
      analysis.feedback
        .filter((f) => f.result === 'error')
        .forEach((e) => {
          const type = e.type;
          errors ??= {};
          errors[type] = {
            message: e.message,
            suggestions: e.suggestions,
            ...e.extra,
          };
        });
      setTimeout(() => this._analysis.set(analysis));
      return errors;
    });
  }
}
