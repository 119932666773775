import { inject, LOCALE_ID } from '@angular/core';
import { OptionsDictionary, TranslationKeys, zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import { PasswordAnalysis } from './PasswordAnalysis';
import { DefaultPasswordFormControlOptions, PasswordFormControlOptions } from './PasswordFormControlOptions';

interface LangPack {
  translations: TranslationKeys;
  dictionary: OptionsDictionary;
}

export class PasswordAnalyzer {
  public readonly lang = inject(LOCALE_ID);
  public readonly langShort = this.lang.split('-')[0];
  private readonly _zxcvbnts = (window as any).zxcvbnts ?? {};
  private readonly _commonLangPack = zxcvbnCommonPackage;
  private readonly _langPack?: LangPack = this._zxcvbnts[`language-${this.lang}`] ?? this._zxcvbnts[`language-${this.langShort}`];

  private readonly _formOptions: Required<PasswordFormControlOptions>;

  constructor(options?: PasswordFormControlOptions) {
    this._formOptions = {
      ...DefaultPasswordFormControlOptions,
      ...options,
    };
    zxcvbnOptions.setOptions({
      translations: this._langPack?.translations,
      graphs: this._commonLangPack.adjacencyGraphs,
      dictionary: {
        ...this._commonLangPack.dictionary,
        ...this._langPack?.dictionary,
      },
    });
  }

  public analyzePassword(password: string) {
    const opts = this._formOptions;
    const analysis = zxcvbn(password);
    const result: PasswordAnalysis = {
      score: analysis.score,
      feedback: [],
    };
    if (analysis.feedback.warning) {
      result.feedback.push({
        type: 'suggestions',
        result: 'warning',
        message: analysis.feedback.warning,
        suggestions: analysis.feedback.suggestions,
      });
    }

    if (opts.minLength !== null) {
      if (password.length < opts.minLength) {
        result.feedback.push({
          type: 'passwordLengthMin',
          result: 'error',
          message: `Minimum ${opts.minLength} characters.`,
          suggestions: [],
          extra: {
            requiredLength: opts.minLength,
            actualLength: password.length,
          },
        });
        result.score = -1;
      } else {
        result.feedback.push({
          type: 'passwordLengthMin',
          result: 'ok',
          message: `Password is long enough.`,
          suggestions: [],
          extra: {
            requiredLength: opts.minLength,
            actualLength: password.length,
          },
        });
      }
    }

    if (opts.maxLength !== null) {
      if (password.length > opts.maxLength) {
        result.feedback.push({
          type: 'passwordLengthMax',
          result: 'error',
          message: `Maximum ${opts.maxLength} characters.`,
          suggestions: [],
          extra: {
            requiredLength: opts.maxLength,
            actualLength: password.length,
          },
        });
        result.score = -1;
      }
    }

    if (opts.requireAlpha) {
      if (!/[a-zA-Z]/.test(password)) {
        result.feedback.push({
          type: 'requireAlpha',
          result: 'error',
          message: 'Must contain at least one letter.',
          suggestions: [],
        });
        result.score = -1;
      } else {
        result.feedback.push({
          type: 'requireAlpha',
          result: 'ok',
          message: 'Contains at least one letter.',
          suggestions: [],
        });
      }
    }

    if (opts.requireNumber) {
      if (!/\d/.test(password)) {
        result.feedback.push({
          type: 'requireNumber',
          result: 'error',
          message: 'Must contain at least one number.',
          suggestions: [],
        });
        result.score = -1;
      } else {
        result.feedback.push({
          type: 'requireNumber',
          result: 'ok',
          message: 'Contains at least one number',
          suggestions: [],
        });
      }
    }

    if (opts.requireUppercase) {
      if (!/[A-Z]/.test(password)) {
        result.feedback.push({
          type: 'requireUppercase',
          result: 'error',
          message: 'Must contain at least one uppercase letter.',
          suggestions: [],
        });
        result.score = -1;
      } else {
        result.feedback.push({
          type: 'requireUppercase',
          result: 'ok',
          message: 'Contains at least one uppercase letter.',
          suggestions: [],
        });
      }
    }

    if (opts.requireLowercase) {
      if (!/[a-z]/.test(password)) {
        result.feedback.push({
          type: 'requireLowercase',
          result: 'error',
          message: 'Must contain at least one lowercase letter.',
          suggestions: [],
        });
        result.score = -1;
      } else {
        result.feedback.push({
          type: 'requireLowercase',
          result: 'ok',
          message: 'Contains at least one lowercase letter.',
          suggestions: [],
        });
      }
    }

    if (opts.requireSpecial) {
      if (!/[^a-zA-Z\d]/.test(password)) {
        result.feedback.push({
          type: 'requireSpecial',
          result: 'error',
          message: 'Must contain at least one special character.',
          suggestions: [],
        });
        result.score = -1;
      } else {
        result.feedback.push({
          type: 'requireSpecial',
          result: 'ok',
          message: 'Contains at least one special character.',
          suggestions: [],
        });
      }
    }

    if (opts.errorStrength !== null) {
      if (result.score < opts.errorStrength) {
        result.feedback.push({
          type: 'strength',
          result: 'error',
          message: `Password is too weak.`,
          suggestions: analysis.feedback.warning === null && analysis.feedback.suggestions.length ? analysis.feedback.suggestions : [],
          extra: {
            requiredStrength: opts.errorStrength,
            actualStrength: result.score,
          },
        });
      } else {
        result.feedback.push({
          type: 'strength',
          result: 'ok',
          message: `Password is strong enough.`,
          suggestions: [],
          extra: {
            requiredStrength: opts.errorStrength,
            actualStrength: result.score,
          },
        });
      }
    }

    if (opts.warnStrength !== null) {
      const strengthIdx = result.feedback.findIndex((f) => f.type === 'strength');
      const strength = result.feedback[strengthIdx];
      const error = strength?.result === 'error';
      if (!error && result.score < opts.warnStrength) {
        if (strengthIdx !== -1) {
          result.feedback.splice(strengthIdx, 1);
        }
        result.feedback.push({
          type: 'strength',
          result: 'warning',
          message: `Password is ok but could be stronger`,
          suggestions: analysis.feedback.warning === null && analysis.feedback.suggestions.length ? analysis.feedback.suggestions : [],
          extra: {
            suggestedStrength: opts.warnStrength,
            actualStrength: result.score,
          },
        });
      }
    }

    if (opts.warnStrength === null && opts.errorStrength === null) {
      result.feedback.push({
        type: 'strength',
        result: 'ok',
        message: `Password strength is not being checked.`,
        suggestions: [],
        extra: {
          actualStrength: result.score,
        },
      });
    }
    return result;
  }
}
