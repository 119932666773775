import { AbstractControl, ValidationErrors } from '@angular/forms';

interface Coordinate {
  longitude?: number | null;
  latitude?: number | null;
}

export function validateLongitude(value: number | null | undefined): ValidationErrors | null {
  if (typeof value === 'number') {
    if (value < -180 || value > 180) {
      return {
        longitude: {
          value,
          min: -180,
          max: 180,
        },
      };
    }
  }
  return null;
}

export function validateLatitude(value: number | null | undefined): ValidationErrors | null {
  if (typeof value === 'number') {
    if (value < -90 || value > 90) {
      return {
        latitude: {
          value,
          min: -90,
          max: 90,
        },
      };
    }
  }
  return null;
}

export function validateCoordinates(value: Coordinate | null | undefined): ValidationErrors | null {
  let errors: ValidationErrors | null = null;
  if (value) {
    const longitudeErrors = validateLongitude(value.longitude);
    if (longitudeErrors) {
      errors ??= {};
      errors = { ...errors, ...longitudeErrors };
    }
    const latitudeErrors = validateLatitude(value.latitude);
    if (latitudeErrors) {
      errors ??= {};
      errors = { ...errors, ...latitudeErrors };
    }
  }
  return errors;
}

export function longitudeValidator(control: AbstractControl<number | null>): ValidationErrors | null {
  return validateLongitude(control.value);
}

export function latitudeValidator(control: AbstractControl<number | null>): ValidationErrors | null {
  return validateLatitude(control.value);
}

export function coordinatesValidator(control: AbstractControl<Coordinate | null>): ValidationErrors | null {
  return validateCoordinates(control.value);
}
