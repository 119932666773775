import { AbstractControl, ValidationErrors } from '@angular/forms';

export function validateUSAZipCode(value: string | null | undefined): ValidationErrors | null {
  value = (value ?? '').trim();
  if (value) {
    const zipCode = value.replace(/\s/g, '');
    const regex = /^\d{5}(?:[-\s]\d{4})?$/;
    if (!regex.test(zipCode)) {
      return {
        format: $localize`:@@address.postalCode.error.invalidUSAZipCode:Invalid zip code`,
      };
    }
  }
  return null;
}

export function usaZipCodeValidator(control: AbstractControl): ValidationErrors | null {
  return validateUSAZipCode(control.value);
}
