import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export enum CharactersAllowedResult {
  Valid = 0,
  AllowError = 1,
  DenyError = 2,
}

export function checkValidCharacters(value: string, allowed?: RegExp | null, denied?: RegExp | null): number {
  allowed = allowed ? new RegExp(allowed.source, allowed.flags + 'gm') : undefined;
  denied = denied ? new RegExp(denied.source, denied.flags + 'gm') : undefined;

  let error = CharactersAllowedResult.Valid;

  if (allowed && value.match(allowed)?.length !== value.length) {
    error |= CharactersAllowedResult.AllowError;
  }

  if (denied && (value.match(denied)?.length ?? 0) > 0) {
    error |= CharactersAllowedResult.DenyError;
  }

  return error;
}

interface AllowedCharactersValidatorOptions {
  allowed?: RegExp | null;
  denied?: RegExp | null;
  allowedErrorMessage?: string;
  deniedErrorMessage?: string;
}

export const AllowedCharactersValidator = ({
  allowed,
  denied,
  allowedErrorMessage,
  deniedErrorMessage,
}: AllowedCharactersValidatorOptions): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (value === null || value === undefined || value === '') {
      return null;
    }

    const result = checkValidCharacters(value, allowed, denied);
    const error = {} as any;

    if (result & CharactersAllowedResult.AllowError) {
      error.characterWhitelist = {
        value,
        allowed: allowed!.source,
        errorMessage: allowedErrorMessage ?? 'One or more chars are not allowed',
      };
    }
    if (result & CharactersAllowedResult.DenyError) {
      error.characterBacklist = { value, denied: denied!.source, errorMessage: deniedErrorMessage ?? 'One or more chars are not allowed' };
    }
    return result === CharactersAllowedResult.Valid ? null : error;
  };
};
