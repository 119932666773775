export const countryRegionNames = {
  CA: {
    AB: $localize`:@@address.region.CA.AB:Alberta`,
    BC: $localize`:@@address.region.CA.BC:British Columbia`,
    MB: $localize`:@@address.region.CA.MB:Manitoba`,
    NB: $localize`:@@address.region.CA.NB:New Brunswick`,
    NL: $localize`:@@address.region.CA.NL:Newfoundland and Labrador`,
    NS: $localize`:@@address.region.CA.NS:Nova Scotia`,
    NT: $localize`:@@address.region.CA.NT:Northwest Territories`,
    NU: $localize`:@@address.region.CA.NU:Nunavut`,
    ON: $localize`:@@address.region.CA.ON:Ontario`,
    PE: $localize`:@@address.region.CA.PE:Prince Edward Island`,
    QC: $localize`:@@address.region.CA.QC:Québec`,
    SK: $localize`:@@address.region.CA.SK:Saskatchewan`,
    YT: $localize`:@@address.region.CA.YT:Yukon`,
  } as const,
  US: {
    AL: $localize`:@@address.region.US.AL:Alabama`,
    AK: $localize`:@@address.region.US.AK:Alaska`,
    AZ: $localize`:@@address.region.US.AZ:Arizona`,
    AR: $localize`:@@address.region.US.AR:Arkansas`,
    CA: $localize`:@@address.region.US.CA:California`,
    CO: $localize`:@@address.region.US.CO:Colorado`,
    CT: $localize`:@@address.region.US.CT:Connecticut`,
    DE: $localize`:@@address.region.US.DE:Delaware`,
    DC: $localize`:@@address.region.US.DC:District of Columbia`,
    FL: $localize`:@@address.region.US.FL:Florida`,
    GA: $localize`:@@address.region.US.GA:Georgia`,
    HI: $localize`:@@address.region.US.HI:Hawaii`,
    ID: $localize`:@@address.region.US.ID:Idaho`,
    IL: $localize`:@@address.region.US.IL:Illinois`,
    IN: $localize`:@@address.region.US.IN:Indiana`,
    IA: $localize`:@@address.region.US.IA:Iowa`,
    KS: $localize`:@@address.region.US.KS:Kansas`,
    KY: $localize`:@@address.region.US.KY:Kentucky`,
    LA: $localize`:@@address.region.US.LA:Louisiana`,
    ME: $localize`:@@address.region.US.ME:Maine`,
    MD: $localize`:@@address.region.US.MD:Maryland`,
    MA: $localize`:@@address.region.US.MA:Massachusetts`,
    MI: $localize`:@@address.region.US.MI:Michigan`,
    MN: $localize`:@@address.region.US.MN:Minnesota`,
    MS: $localize`:@@address.region.US.MS:Mississippi`,
    MO: $localize`:@@address.region.US.MO:Missouri`,
    MT: $localize`:@@address.region.US.MT:Montana`,
    NE: $localize`:@@address.region.US.NE:Nebraska`,
    NV: $localize`:@@address.region.US.NV:Nevada`,
    NH: $localize`:@@address.region.US.NH:New Hampshire`,
    NJ: $localize`:@@address.region.US.NJ:New Jersey`,
    NM: $localize`:@@address.region.US.NM:New Mexico`,
    NY: $localize`:@@address.region.US.NY:New York`,
    NC: $localize`:@@address.region.US.NC:North Carolina`,
    ND: $localize`:@@address.region.US.ND:North Dakota`,
    OH: $localize`:@@address.region.US.OH:Ohio`,
    OK: $localize`:@@address.region.US.OK:Oklahoma`,
    OR: $localize`:@@address.region.US.OR:Oregon`,
    PA: $localize`:@@address.region.US.PA:Pennsylvania`,
    RI: $localize`:@@address.region.US.RI:Rhode Island`,
    SC: $localize`:@@address.region.US.SC:South Carolina`,
    SD: $localize`:@@address.region.US.SD:South Dakota`,
    TN: $localize`:@@address.region.US.TN:Tennessee`,
    TX: $localize`:@@address.region.US.TX:Texas`,
    UT: $localize`:@@address.region.US.UT:Utah`,
    VT: $localize`:@@address.region.US.VT:Vermont`,
    VI: $localize`:@@address.region.US.VI:Virgin Islands`,
    VA: $localize`:@@address.region.US.VA:Virginia`,
    WA: $localize`:@@address.region.US.WA:Washington`,
    WV: $localize`:@@address.region.US.WV:West Virginia`,
    WI: $localize`:@@address.region.US.WI:Wisconsin`,
    WY: $localize`:@@address.region.US.WY:Wyoming`,
  },
} as const;
