import { GooglePlacesPlaceDetailsResponse } from 'google-data';
import { countryCodeAliases } from './data/countryCodeAliases';
import { countryNames } from './data/countryNames';
import { countryRegionCodeAliases } from './data/countryRegionCodeAliases';
import { countryRegionNames } from './data/countryRegionNames';
import { postalCodeLabels } from './data/postalCodeLabels';
import { postalCodePlaceholders } from './data/postalCodePlaceholders';
import { regionLabels } from './data/regionLabels';
import { regionPlaceholders } from './data/regionPlaceholders';
import { Address } from './types';

export class AddressUtil {
  static resolveCountryCode(countryAlias: string): string {
    return countryCodeAliases[countryAlias?.toUpperCase() as keyof typeof countryCodeAliases] ?? countryAlias;
  }

  static resolveRegionCode(countryAlias: string, regionAlias: string): string {
    const cc = this.resolveCountryCode(countryAlias);
    const c = countryRegionCodeAliases[cc as keyof typeof countryRegionCodeAliases];
    const r = c?.[regionAlias?.toUpperCase() as keyof typeof c];
    return r ?? regionAlias;
  }

  static getPostalCodeLabel(countryCode: string) {
    return postalCodeLabels[this.resolveCountryCode(countryCode) as keyof typeof postalCodeLabels] ?? postalCodeLabels['CA'];
  }

  static getPostalCodePlaceholder(countryCode: string) {
    return (
      postalCodePlaceholders[this.resolveCountryCode(countryCode) as keyof typeof postalCodePlaceholders] ?? postalCodePlaceholders['CA']
    );
  }

  static getRegionLabel(countryCode: string) {
    return regionLabels[this.resolveCountryCode(countryCode) as keyof typeof regionLabels] ?? regionLabels['CA'];
  }

  static getRegionPlaceholder(countryCode: string) {
    return regionPlaceholders[this.resolveCountryCode(countryCode) as keyof typeof regionPlaceholders] ?? regionPlaceholders['CA'];
  }

  static getCountryName(countryCode: string) {
    return countryNames[this.resolveCountryCode(countryCode) as keyof typeof countryNames] ?? countryCode;
  }

  static getRegionName(countryCode: string, regionCode: string) {
    const cc = this.resolveCountryCode(countryCode);
    const rc = this.resolveRegionCode(countryCode, regionCode);
    const c = countryRegionNames[cc as keyof typeof countryRegionNames];
    const r = c?.[rc as keyof typeof c];
    return r ?? regionCode;
  }

  static hasRegion(countryCode: string, regionCode: string) {
    return !!this.getRegionName(countryCode, regionCode);
  }

  static getCountryCodes(): string[] {
    return ['CA']; //Object.keys(countryNames);
  }

  static getRegionCodes(countryCode: string): string[] {
    const cc = this.resolveCountryCode(countryCode);
    const c = countryRegionNames[cc as keyof typeof countryRegionNames];
    return c ? Object.keys(c) : [];
  }

  static placeToAddress(place: GooglePlacesPlaceDetailsResponse | null): Address | null {
    if (!place) return null;

    let address: Address | null = null;

    const types = place.types;

    if (types.includes('subpremise') || types.includes('premise') || types.includes('street_address') || types.includes('establishment')) {
      address = this.#defaultPlaceToAddress(place);
    }

    return address;
  }

  static #defaultPlaceToAddress(place: GooglePlacesPlaceDetailsResponse): Address {
    const address = {} as Address;

    address.line1 = place.addressComponents.find((c) => c.types.includes('street_number'))?.shortText ?? '';
    address.line1 += ' ' + (place.addressComponents.find((c) => c.types.includes('route'))?.shortText ?? '');
    address.line1 = address.line1.trim();

    address.locality = place.addressComponents.find((c) => c.types.includes('locality'))?.shortText ?? '';
    address.region = place.addressComponents.find((c) => c.types.includes('administrative_area_level_1'))?.shortText ?? '';
    address.postalCode = place.addressComponents.find((c) => c.types.includes('postal_code'))?.shortText ?? '';
    address.country = place.addressComponents.find((c) => c.types.includes('country'))?.shortText ?? '';

    if (place.location?.latitude || place.location?.longitude) {
      address.latitude = place.location.latitude || undefined;
      address.longitude = place.location.longitude || undefined;
    }

    const sp = place.addressComponents.find((c) => c.types.includes('subpremise'))?.shortText ?? '';
    if (sp) {
      address.line2 = sp;
      if (!isNaN(+sp)) {
        address.line2 = `# ${sp}`;
      }
    }

    return address;
  }
}
