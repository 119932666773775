import { addressValidator } from './address.validator';
import { coordinatesValidator, latitudeValidator, longitudeValidator } from './coordinates.validator';
import { postalCodeValidator } from './postalcode.validator';
import { regionValidator } from './region.validator';

export abstract class AddressValidators {
  static postalCode = postalCodeValidator;
  static region = regionValidator;
  static address = addressValidator;
  static coordinates = coordinatesValidator;
  static longitude = longitudeValidator;
  static latitude = latitudeValidator;
}
