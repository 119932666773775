import { Validators } from '@angular/forms';
import { FormControlEx } from '../../base/FormControlEx';
import { DefaultNumberFormControlOptions, NumberFormControlOptions, NumberFormMultiplesOfStrategy } from './NumberFormControlOptions';

export abstract class NumberFormControl extends FormControlEx<number, NumberFormControlOptions>() {
  constructor(value: number, opts?: NumberFormControlOptions & { nonNullable: true });
  constructor(value: number | null, opts?: NumberFormControlOptions & { nonNullable?: false });
  constructor(value: number | null, opts?: NumberFormControlOptions) {
    super(value, {
      ...DefaultNumberFormControlOptions,
      ...opts,
    });
    const options = this.options!;
    if (options.required) {
      this.addValidators(Validators.required);
    }

    if ((options.min ?? null) !== null) {
      this.addValidators(Validators.min(opts!.min!));
    }

    if ((options.max ?? null) !== null) {
      this.addValidators(Validators.max(opts!.max!));
    }

    if (
      options.multiplesOf !== null &&
      (options.multiplesOfStrategy === NumberFormMultiplesOfStrategy.RestrictOnIncrementOrError ||
        options.multiplesOfStrategy === NumberFormMultiplesOfStrategy.Error)
    ) {
      const snap = options.multiplesOf!;
      this.addValidators((c) => {
        const snapped = Math.round(c.value / snap) * snap;
        if (snapped !== c.value) {
          if (c.value !== options.min || c.value !== options.max) {
            return {
              multipleOf: {
                multiple: snap,
                actualValue: c.value,
                snappedValue: snapped,
              },
            };
          }
        }
        return null;
      });
    }
  }
}
