import { Provider, Type } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { EarlyErrorStateMatcher } from './ErrorStateMatcher';

export function provideErrorStateMatcher(matcher: Type<ErrorStateMatcher> = EarlyErrorStateMatcher): Provider {
  return {
    provide: ErrorStateMatcher,
    useClass: matcher,
  };
}
