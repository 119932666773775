import { AbstractControl, ValidationErrors } from '@angular/forms';

export function validateCanadianPostalCode(value: string | null | undefined): ValidationErrors | null {
  value = (value ?? '').trim();
  if (value) {
    const postalCode = value.replace(/\s/g, '').toUpperCase();
    const regex = /^[A-Z]\d[A-Z]\d[A-Z]\d$/;
    if (!regex.test(postalCode)) {
      return {
        format: $localize`:@@address.postalCode.error.invalidCanadianPostalCode:Invalid postal code`,
      };
    }
  }
  return null;
}

export function canadianPostalCodeValidator(control: AbstractControl): ValidationErrors | null {
  return validateCanadianPostalCode(control.value);
}
