import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AddressUtil } from '../AddressUtil';

export function validateRegion(value: string | null | undefined, country: string | null | undefined): ValidationErrors | null {
  value = (value ?? '').trim();
  country = AddressUtil.resolveCountryCode((country ?? '').trim());
  if (value) {
    if (!country) {
      return {
        country,
      };
    }
    if (!AddressUtil.hasRegion(country, value)) {
      return {
        region: {
          country,
          value,
        },
      };
    }
  }
  return null;
}

export function regionValidator(countryField: string = 'country'): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return validateRegion(control.value, control.parent?.get(countryField)?.value);
  };
}
