import { FormControlOptionsEx } from '../../options';
import { NumberFormMultiplesOfStrategy, NumberFormRangeStrategy } from '../number';

export interface IntegerFormControlOptions extends FormControlOptionsEx {
  min?: number | null;
  max?: number | null;
  rangeStrategy?: NumberFormRangeStrategy;
  required?: boolean;
  step?: number | null;
  stepCtrl?: number | null;
  stepShift?: number | null;
  multiplesOf?: number | null;
  multiplesOfStrategy?: NumberFormMultiplesOfStrategy;
  formatMinIntegerDigits?: number | null;
}

export const DefaultIntegerFormControlOptions: Required<IntegerFormControlOptions> = {
  additionalAsyncValidators: [],
  additionalValidators: [],
  min: null,
  max: null,
  step: null,
  stepCtrl: null,
  stepShift: null,
  multiplesOf: null,
  formatMinIntegerDigits: null,
  rangeStrategy: NumberFormRangeStrategy.RestrictOnIncrement,
  multiplesOfStrategy: NumberFormMultiplesOfStrategy.RestrictOnIncrementOrIgnore,
  required: false,
  nonNullable: false,
  updateOn: 'change',
};
