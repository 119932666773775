import { FormControl } from '@angular/forms';
import { FormControlOptionsEx } from '../../options';

export interface TextFormControlOptions extends FormControlOptionsEx {
  maxLength?: number | null;
  minLength?: number | null;
  lengthValidationStrategy?: 'restrict' | 'error';
  charactersAllowed?: RegExp | null;
  characterAllowedErrorMessage?: string;
  characterDenied?: RegExp | null;
  characterDeniedErrorMessage?: string;
  characterValidationStrategy?: 'restrict' | 'error';
  required?: boolean;
  matchesControl?: string | FormControl<string> | null;
  matchesControlLabel?: string | null;
  trim?: boolean;
}

export const DefaultTextFormControlOptions: Required<TextFormControlOptions> = {
  additionalAsyncValidators: [],
  additionalValidators: [],
  characterDenied: null,
  characterDeniedErrorMessage: 'One or more chars are not allowed',
  charactersAllowed: null,
  characterAllowedErrorMessage: 'One or more chars are not allowed',
  characterValidationStrategy: 'restrict',
  minLength: null,
  maxLength: null,
  lengthValidationStrategy: 'restrict',
  required: false,
  nonNullable: false,
  trim: true,
  matchesControl: null,
  matchesControlLabel: null,
  updateOn: 'change',
};
