import { NumberFormControl, NumberFormControlOptions } from '../number';
import { DefaultIntegerFormControlOptions, IntegerFormControlOptions } from './IntegerFormControlOptions';

function intToNumberOptions(opts: IntegerFormControlOptions): NumberFormControlOptions {
  return {
    additionalAsyncValidators: opts.additionalAsyncValidators,
    additionalValidators: opts.additionalValidators,
    min: typeof opts.min === 'number' ? Math.floor(opts.min) : opts.min,
    max: typeof opts.max === 'number' ? Math.floor(opts.max) : opts.max,
    step: typeof opts.step === 'number' ? Math.floor(opts.step) : opts.step,
    stepCtrl: typeof opts.stepCtrl === 'number' ? Math.floor(opts.stepCtrl) : opts.stepCtrl,
    stepShift: typeof opts.stepShift === 'number' ? Math.floor(opts.stepShift) : opts.stepShift,
    multiplesOf: (typeof opts.multiplesOf === 'number' ? Math.floor(opts.multiplesOf) : opts.multiplesOf) ?? 1,
    formatMinIntegerDigits:
      typeof opts.formatMinIntegerDigits === 'number' ? Math.floor(opts.formatMinIntegerDigits) : opts.formatMinIntegerDigits,
    formatMinFractionDigits: 0,
    formatMaxFractionDigits: 0,
    rangeStrategy: opts.rangeStrategy,
    multiplesOfStrategy: opts.multiplesOfStrategy,
    required: opts.required,
    nonNullable: opts.nonNullable,
    updateOn: opts.updateOn,
  };
}

export class IntegerFormControl extends NumberFormControl {
  constructor(value: number, opts?: IntegerFormControlOptions & { nonNullable: true });
  constructor(value: number | null, opts?: IntegerFormControlOptions & { nonNullable?: false });
  constructor(value: number | null, opts?: IntegerFormControlOptions) {
    super(
      value,
      intToNumberOptions({
        ...DefaultIntegerFormControlOptions,
        ...opts,
      }) as any,
    );
  }
}
