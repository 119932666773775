import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AddressUtil } from '../AddressUtil';
import { validateCanadianPostalCode } from './canadian-postalcode.validator';
import { validateUSAZipCode } from './usa-zipcode.validator';

export function validatePostalCode(value: string | null | undefined, country: string | null | undefined): ValidationErrors | null {
  value = (value ?? '').trim();
  country = AddressUtil.resolveCountryCode((country ?? '').trim());
  if (value) {
    switch (country) {
      case 'CA':
        return validateCanadianPostalCode(value);
      case 'US':
        return validateUSAZipCode(value);
      default:
        return {
          country,
        };
    }
  }
  return null;
}

export function postalCodeValidator(countryField: string = 'country'): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return validatePostalCode(control.value, control.parent?.get(countryField)?.value);
  };
}
