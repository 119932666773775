import { TextFormControlOptions } from '../text';

export interface PasswordFormControlOptions extends TextFormControlOptions {
  warnStrength?: number | null;
  errorStrength?: number | null;
  requireAlpha?: boolean;
  requireNumber?: boolean;
  requireUppercase?: boolean;
  requireLowercase?: boolean;
  requireSpecial?: boolean;
}

export const DefaultPasswordFormControlOptions: Required<PasswordFormControlOptions> = {
  additionalAsyncValidators: [],
  additionalValidators: [],
  characterDenied: null,
  characterDeniedErrorMessage: 'One or more chars are not allowed',
  charactersAllowed: null,
  characterAllowedErrorMessage: 'One or more chars are not allowed',
  characterValidationStrategy: 'restrict',
  minLength: null,
  maxLength: null,
  lengthValidationStrategy: 'restrict',
  required: false,
  nonNullable: false,
  trim: false,
  updateOn: 'change',
  matchesControl: null,
  matchesControlLabel: null,
  warnStrength: null,
  errorStrength: null,
  requireAlpha: false,
  requireNumber: false,
  requireUppercase: false,
  requireLowercase: false,
  requireSpecial: false,
};
