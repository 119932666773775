import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormControlEx } from '../../base/FormControlEx';
import { AllowedCharactersValidator } from '../../validation/AllowedCharactersValidator';
import { DefaultTextFormControlOptions, TextFormControlOptions } from './TextFormControlOptions';

export function ControlsMatchValidator(
  controlOrName: string | AbstractControl,
  options?: { matchLabel?: string | null },
): (control: AbstractControl) => null | ValidationErrors {
  let matchControl: AbstractControl | undefined = undefined;
  let matchControlSub: Subscription | undefined = undefined;
  return (control: AbstractControl) => {
    const cControl = typeof controlOrName === 'string' ? (control.parent?.get(controlOrName) ?? undefined) : controlOrName;
    if (cControl !== matchControl) {
      matchControl = cControl;
      matchControlSub?.unsubscribe();
      matchControlSub = matchControl?.valueChanges.subscribe(() => {
        control.updateValueAndValidity({ onlySelf: true });
      });
    }
    if (matchControl) {
      if (matchControl?.value !== control.value) {
        return {
          'matches-control': {
            label: options?.matchLabel ?? null,
            matchValue: matchControl.value,
            value: control.value,
          },
        };
      }
    }
    return null;
  };
}

export class TextFormControl extends FormControlEx<string, TextFormControlOptions>() {
  constructor(value: string, opts?: TextFormControlOptions & { nonNullable: true });
  constructor(value: string | null, opts?: TextFormControlOptions & { nonNullable?: false });
  constructor(value: string | null, opts?: TextFormControlOptions) {
    super(value, {
      ...DefaultTextFormControlOptions,
      ...opts,
    });
    const options = this.options!;
    if (options.required) {
      this.addValidators(Validators.required);
    }
    if (!!options.minLength) {
      this.addValidators(Validators.minLength(options.minLength));
    }
    if (!!options.maxLength) {
      this.addValidators(Validators.maxLength(options.maxLength));
    }
    if (options.matchesControl) {
      this.addValidators(ControlsMatchValidator(options.matchesControl, { matchLabel: options.matchesControlLabel }));
    }
    if (options.charactersAllowed !== undefined || options.characterDenied !== undefined) {
      this.addValidators(
        AllowedCharactersValidator({
          allowed: options.charactersAllowed,
          denied: options.characterDenied,
        }),
      );
    }
  }

  private trimValue(value: string): string;
  private trimValue(value?: string): string | undefined;
  private trimValue(value?: string): string | undefined {
    return (this.options!.trim ?? true) && value ? value.trim() : value;
  }

  override patchValue(
    value: string,
    options?: { onlySelf?: boolean; emitEvent?: boolean; emitModelToViewChange?: boolean; emitViewToModelChange?: boolean } | undefined,
  ): void {
    super.patchValue(this.trimValue(value), options);
  }

  override setValue(
    value: string,
    options?: { onlySelf?: boolean; emitEvent?: boolean; emitModelToViewChange?: boolean; emitViewToModelChange?: boolean } | undefined,
  ): void {
    super.setValue(this.trimValue(value), options);
  }

  override reset(value?: string, options?: { onlySelf?: boolean; emitEvent?: boolean } | undefined): void {
    super.reset(this.trimValue(value), options);
  }
}
