import { Injectable } from '@angular/core';
import { GooglePlacesPlaceDetailsResponse } from 'google-data';
import { AddressUtil } from './AddressUtil';
import { Address } from './types';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  getCountryCodes(): string[] {
    return AddressUtil.getCountryCodes();
  }

  getRegionCodes(countryCode: string): string[] {
    return AddressUtil.getRegionCodes(countryCode);
  }

  getCountryName(countryCode: string): string {
    return AddressUtil.getCountryName(countryCode);
  }

  getRegionName(countryCode: string, regionCode: string): string {
    return AddressUtil.getRegionName(countryCode, regionCode);
  }

  getRegionLabel(countryCode: string): string {
    return AddressUtil.getRegionLabel(countryCode);
  }

  getRegionPlaceholder(countryCode: string): string {
    return AddressUtil.getRegionPlaceholder(countryCode);
  }

  getPostalCodeLabel(countryCode: string): string {
    return AddressUtil.getPostalCodeLabel(countryCode);
  }

  getPostalCodePlaceholder(countryCode: string): string {
    return AddressUtil.getPostalCodePlaceholder(countryCode);
  }

  placeToAddress(place: GooglePlacesPlaceDetailsResponse | null): Address | null {
    return AddressUtil.placeToAddress(place);
  }
}
