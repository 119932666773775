import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Address } from '../types';
import { validatePostalCode } from './postalcode.validator';
import { validateRegion } from './region.validator';

export function validateAddress(value: Address | null | undefined): ValidationErrors | null {
  let errors: ValidationErrors | null = null;
  if (value) {
    const name = ((value.name as string) ?? '').trim();
    const line1 = ((value.line1 as string) ?? '').trim();
    const line2 = ((value.line2 as string) ?? '').trim();
    const locality = ((value.locality as string) ?? '').trim();
    const region = ((value.region as string) ?? '').trim();
    const postalCode = ((value.postalCode as string) ?? '').trim();
    const country = ((value.country as string) ?? '').trim();

    const notEmpty = line1 || line2 || locality || region || postalCode || country;
    if (notEmpty) {
      const missing = !line1 || !locality || !region || !postalCode || !country || !name;
      if (missing) {
        errors ??= {};
        errors['missing'] = {
          name: !name,
          line1: !line1,
          locality: !locality,
          region: !region,
          postalCode: !postalCode,
          country: !country,
        };
      }

      const postalErrors = validatePostalCode(postalCode, country);
      if (postalErrors) {
        errors ??= {};
        errors['postal'] = postalErrors;
      }

      const regionErrors = validateRegion(region, country);
      if (regionErrors) {
        errors ??= {};
        errors['region'] = regionErrors;
      }
    }
  }
  return errors;
}

export function addressValidator(control: AbstractControl<Address | null>): ValidationErrors | null {
  return validateAddress(control.value);
}
