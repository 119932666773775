import { FormControlOptionsEx } from '../../options';

export enum NumberFormRangeStrategy {
  RestrictOnIncrement = 'restrict_increment',
  Restrict = 'restrict',
  Error = 'error',
}

export enum NumberFormMultiplesOfStrategy {
  RestrictOnIncrementOrIgnore = 'restrict_increment_or_ignore',
  RestrictOnIncrementOrError = 'restrict_increment_or_error',
  Restrict = 'restrict',
  Error = 'error',
}

export interface NumberFormControlOptions extends FormControlOptionsEx {
  min?: number | null;
  max?: number | null;
  rangeStrategy?: NumberFormRangeStrategy;
  required?: boolean;
  step?: number | null;
  stepCtrl?: number | null;
  stepShift?: number | null;
  multiplesOf?: number | null;
  multiplesOfStrategy?: NumberFormMultiplesOfStrategy;
  formatMinIntegerDigits?: number | null;
  formatMinFractionDigits?: number | null;
  formatMaxFractionDigits?: number | null;
}

export const DefaultNumberFormControlOptions: Required<NumberFormControlOptions> = {
  additionalAsyncValidators: [],
  additionalValidators: [],
  min: null,
  max: null,
  step: null,
  stepCtrl: null,
  stepShift: null,
  multiplesOf: null,
  formatMinIntegerDigits: null,
  formatMinFractionDigits: null,
  formatMaxFractionDigits: null,
  rangeStrategy: NumberFormRangeStrategy.RestrictOnIncrement,
  multiplesOfStrategy: NumberFormMultiplesOfStrategy.RestrictOnIncrementOrIgnore,
  required: false,
  nonNullable: false,
  updateOn: 'change',
};
